
export default {
  name: 'HeroTwentyFour',
  data() {
    return {
      bgImg: '',
      deviceImg: '',
    }
  },
  created() {
    this.deviceImg =
      this.$store.state.page?.content?.files?.['networks.hero.device_img'] || ''
    this.bgImg = this.$store.state.page?.content?.bgImage || ''
  },
}
