
const THEMES = {
  dark: {
    text: 'text-grey-400',
    label: 'group-hover:text-white group-focus:text-white',
    filled: 'text-white',
    readonly: 'bg-gray-1 border-gray-2',
    readonlyText: 'text-gray-2',
    error: 'text-red-2',
    symbolCount: 'text-gray-3',
    mainBorder: 'border-white',
    outline: 'shadow-outline-gray group-hover:border-gray-4',
    input: {
      default:
        ' focus:shadow-outline-white-2x focus-within:shadow-outline-white-2x hover:border-grey-600 hover:focus-within:border-gray-4 bg-grey-800',
      error: 'shadow-outline-danger-light',
      success: 'shadow-outline-success-dark',
    },
    loading: 'shadow-none',
  },
  default: {
    text: 'text-grey-600',
    filled: 'text-blue-800',
    label: 'text-grey-600',
    readonly: 'bg-gray-4 text-gray-2',
    readonlyText: 'text-gray-2',
    error: 'text-pink-700',
    symbolCount: 'text-gray-2',
    mainBorder: 'border-gray-3',
    outline: 'shadow-outline-gray',
    input: {
      default:
        ' focus-within:text-blue-800 bg-white focus:shadow-outline-blue-800-2x focus-within:shadow-outline-blue-800-2x hover:shadow-outline-gray-2x hover:focus-within:shadow-outline-blue-800-2x bg-transparent',
      error: 'shadow-outline-danger focus-within:text-blue-800',
      success: 'shadow-outline-success focus-within:text-blue-800',
    },
    loading: 'shadow-none',
  },
}

export default {
  props: {
    id: {
      type: String,
      default: () => `${new Date().getTime() + Math.random(3)}`,
    },
    error: {
      type: String,
      default: '',
    },
    success: {
      type: Boolean,
      default: false,
    },
    isFilled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    counter: {
      type: [Boolean, Number],
      default: false,
    },
    comment: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'default',
      validator(theme) {
        return ['default', 'dark'].includes(theme)
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styles() {
      return THEMES?.[this.theme] || THEMES.default
    },
    inputLength() {
      return (this.$parent?.value?.value || this.$parent?.value)?.length || 0
    },
    inputTypeClass() {
      const classList = {
        default: `${this.styles.outline}${
          this.readonly === false ? this.styles.input.default : ''
        }`,
        error: this.styles.input.error,
        success: this.styles.input.success,
      }

      if (this.readonly && this.theme === 'dark')
        classList.default = classList.default.replace(
          'shadow-outline-gray',
          'shadow-outline-gray-darken',
        )

      let type = classList.default
      if (this.error) type = classList.error
      if (this.success) type = classList.success
      return type
    },
  },
}
