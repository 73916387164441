
import { getFormattedDate } from '~/utils'

export default {
  name: 'LatestNews',
  props: {
    post: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    formattedDate() {
      return getFormattedDate(
        this.post.date,
        this.$store.state['date-fns'].activeLocale,
        'MMMM d, y',
      )
    },
  },
  methods: {
    getSlug(post) {
      return post.current_url?.full_url || '#'
    },
  },
}
