
export default {
  name: 'TitleDescription',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    hideDescriptionOnMobile: {
      type: Boolean,
      default: false,
    },
  },
}
