
import Field from './Field.vue'
import { formPropsMixin } from '~/mixins'

export default {
  components: {
    Field,
  },
  mixins: [formPropsMixin],
  props: {
    icon: {
      type: [Boolean, String],
      default: false,
    },
  },
  computed: {
    hasError() {
      return this.error && this.error?.length > 0
    },
  },
  mounted() {
    this.$emit('field-ready')
  },
}
