
export default {
  name: 'CertificationProgram',
  computed: {
    cards() {
      return [
        {
          image:
            this.$store.state.page.content.files[
              'networks.lander.certification_program'
            ],
          title: this.$i18n.t('networks.certification-program'),
          subtitle: this.$i18n.t('networks.certification-program.description'),
          buttonTitle: this.$i18n.t('networks.certification-program.cta'),
          buttonCta: this.$i18n.t('networks.certification-program.cta_link'),
        },
        {
          image:
            this.$store.state.page.content.files[
              'networks.lander.share_your_solution'
            ],
          title: this.$i18n.t('networks.your-solutions'),
          subtitle: this.$i18n.t('networks.your-solutions.description'),
          buttonTitle: this.$i18n.t('networks.your-solutions.cta'),
          buttonCta: this.$i18n.t('networks.your-solutions.cta_link'),
        },
      ]
    },
  },
}
