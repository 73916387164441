
import { formatWebinarDateByLocale } from '~/utils/formatWebinarObject'
export default {
  name: 'CardsWebinarFullScreenWidth',
  props: {
    webinar: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    webinarTime() {
      return formatWebinarDateByLocale(
        this.webinar.startTime,
        this.$store.state['date-fns'].activeLocale,
        'MMMM dd, yyyy // h:mm a O',
      )
    },
  },
}
