
export default {
  name: 'TitleDescription',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_self',
    },
    hideDescriptionOnMobile: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'default',
      validator(value) {
        return ['small', 'default', 'extra-small'].includes(value)
      },
    },
  },
  computed: {
    dynamicProps() {
      const props = {}
      if (this.link) {
        props.href = this.link
        props.target = '_blank'
      }

      return props
    },
    style() {
      switch (this.theme) {
        case 'small':
          return {
            title: 'font-semibold text-2xl lg:text-3.5xl',
            subtitle: 'text-sm',
            card: 'mr-4 lg:mr-8 p-4 lg:p-8 ',
          }
        case 'extra-small':
          return {
            title: 'font-semibold text-base lg:text-2xl whitespace-normal',
            card: 'mr-4 lg:mr-6 py-4 px-6 lg:py-6 lg:px-8',
          }
        default:
          return {
            title: 'font-bold text-2xl lg:text-3.5xl',
            subtitle: '',
            card: 'mr-6 lg:mr-8 py-2 px-4 lg:p-8',
          }
      }
    },
  },
}
