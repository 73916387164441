
export default {
  name: 'BannerDivider',
  props: {
    bgImage: {
      type: [Object, String],
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    subtitleMobile: {
      type: String,
      default: '',
    },
    buttonTitle: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'default',
      validator(variant) {
        return ['default', 'rounded'].includes(variant)
      },
    },
  },
  computed: {
    isDefaultVariant() {
      return this.variant === 'default'
    },
    isRoundedVariant() {
      return this.variant === 'rounded'
    },
    finalBgImage() {
      if (!this.bgImage.mobile) return this.bgImage
      return this.$store.getters['resize/isMobileSmall']
        ? this.bgImage.mobile
        : this.bgImage.desktop
    },
  },
}
