
import { formatWebinarObject } from '@/utils'
import { newsAndWebinarPosts } from '@/queries/news/newsPosts.gql'
export default {
  name: 'NetworksLanding',
  async asyncData({ app, i18n, $config }) {
    const { data } = await app.$gqlQuery({
      query: newsAndWebinarPosts,
      fetchPolicy: 'reload',
      variables: {
        postsLimit: 3,
        domainId: $config.domainId,
        languageCode: i18n.locale,
      },
    })

    if (!data?.latestNews?.data?.length) console.error('No latestNews')
    if (!data?.webinar?.data?.length) console.error('No webinars')

    const hasUpcomingWebinar =
      data?.webinar?.data?.some((item) => item.webinar_past !== true) || false
    const closestUpcomingWebinar = data?.webinar?.data?.filter(
      (item) => item.webinar_past !== true,
    )

    const webinar = hasUpcomingWebinar
      ? closestUpcomingWebinar[closestUpcomingWebinar.length - 1]
      : data?.webinar?.data?.[0]
    return {
      latestNews: data?.latestNews?.data || false,
      webinar: formatWebinarObject(webinar) || false,
    }
  },
  computed: {
    supportCards() {
      return [
        {
          title: this.$i18n.t('networks.support.card1'),
          subtitle: this.$i18n.t('networks.support.card1.description'),
          link: this.$i18n.t('networks.support.card1.url'),
        },
        {
          title: this.$i18n.t('networks.support.card2'),
          subtitle: this.$i18n.t('networks.support.card2.description'),
          link: this.$i18n.t('networks.support.card2.url'),
        },
        {
          title: this.$i18n.t('networks.support.card3'),
          subtitle: this.$i18n.t('networks.support.card3.description'),
          link: this.$i18n.t('networks.support.card3.url'),
        },
      ]
    },
    focusCards() {
      return [
        {
          title: this.$i18n.t('networks.our-focus.card1'),
          subtitle: this.$i18n.t('networks.our-focus.card1.description'),
        },
        {
          title: this.$i18n.t('networks.our-focus.card2'),
          subtitle: this.$i18n.t('networks.our-focus.card2.description'),
        },
        {
          title: this.$i18n.t('networks.our-focus.card3'),
          subtitle: this.$i18n.t('networks.our-focus.card3.description'),
        },
      ]
    },
    rmsBackground() {
      return {
        desktop:
          this.$store.state.page.content.files['networks.lander.rms_desktop'],
        mobile:
          this.$store.state.page.content.files['networks.lander.rms_mobile'],
      }
    },
    deviceBackground() {
      return {
        desktop:
          this.$store.state.page.content.files[
            'networks.lander.our_device_desktop'
          ],
        mobile:
          this.$store.state.page.content.files[
            'networks.lander.our_device_mobile'
          ],
      }
    },
  },
  methods: {
    newsletterError(validation) {
      const { $error, required, email } = validation.form.email
      if ($error && required)
        return this.$i18n.t('form.error.email', {
          name: this.$i18n.t('form.email'),
        })
      else if ($error && email)
        return this.$i18n.t('form.error.field_required', {
          name: this.$i18n.t('form.email'),
        })
      return ''
    },
  },
}
